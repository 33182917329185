import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Animals = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Watching Animals" />
        <meta
          name="twitter:image"
          content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> Watching Animals </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Aug. 13, 2020) </p>
        </div>
        <div className="articleBody">
          <p>
            <span className="highlight">Prologue</span>! So those of y'all that
            have been keeping up with this weblog might be wondering exactly
            what's going on here at
            <br />
            Shoya, Inc. You might be surprised to see my name in the byline of
            this article cuz I know it's technically canon that I was swiftly
            and suddenly{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/blm"
              target="_blank"
              rel="noopener noreferrer"
            >
              ousted from the company
            </a>{" "}
            during the onset of these worldwide (ongoing) protests against
            racism. Don't worry, that's the subject of a future article. For
            now, just do me a favor and put a pin in allat.
          </p>
          <p>
            I know that after a relatively long absence, time presumably spent
            in deep contemplation, I should wanna come on here offering measured
            commentary about what I think we should do given the grim state of
            the world…..so fuck it that's exactly what imma do but just, in the
            form of a listicle though ctfu. In other words, this might read like
            I'm endorsing media escapism on the surface level but all my fellow
            galaxy-brain trillionaires gone know exactly what's between the
            lines 🤝💯.
          </p>
          <p>
            So boom, the way some people compile lists of movies/television on
            the basis of cinematography, writing, directing, editing, here's a
            list of animal performances that shoulda got a nod for 'Best
            Supporting Actor'.
          </p>
          <p>
            I'm excluding any animated animals of any kind, cuz that's exactly
            what's wrong with our politics today fr... Too much zoom calling,
            not enough doom balling (that's a synonym for revolution and you
            heard it here first, MLA cite me dh).
          </p>
          <p className="highlight">
            <i>War Horse</i> (2011) dir. Steven Spielberg
          </p>
          <p>
            This movie is….not good? It's under-historical (not historical
            enough to be called ahistorical), and the foreign characters don't
            even bother to speak foreign languages. If I wanted to hear a German
            who can only speak English, I would just talk to Sil lmao. I get
            that the target demographic was probably children, but all my
            yungbols know a Anglo-Teutonic wet dream when they see one.
          </p>
          <p>
            We been knew Spielberg got that Peter Pan Syndrome or whatever so I
            won't dwell on it, cuz there is some good to come out of this
            particular iteration of it. The horses that played Joey and Topthorn
            (real names not listed on IMDb and{" "}
            <a
              href="https://www.mentalfloss.com/article/90915/11-companies-you-might-not-realize-are-owned-amazon"
              target="_blank"
              rel="noopener noreferrer"
            >
              we know why
            </a>
            ) gave us some solid performances. I was really convinced that they
            loved each other. When they neighed, I prayed.
          </p>
          <p>
            And whenever I get a horse I'm teaching it the handflute thing for
            sure. The way things going now, we all need a horse that could come
            find us in a world war.
          </p>
          <p className="highlight">
            <i>John Wick: Chapter 2 </i>(2017) dir. Antoine Fuqua
          </p>
          <p>
            LOL naw Antoine Fuqua didn't actually direct this but imma go head
            and blackwash this one cuz it's tough. A lot of people consider this
            a rare sequel that outdoes the original -- I'm not sure I agree
            exactly. If the first movie hadn't been so subtle about the
            mythology, all the great worldbuilding that finally happens in this
            second one couldn't be so enjoyable.
          </p>
          <p>
            The acting was on point too. If I had to choose between the
            legendary Laurence Fishburne and the great Lance Reddick……….I'd
            choose Bubba the Blue Nose Pitbull as the supporting actor who I
            thought gave the very most. The chemistry he had with the Baba Yaga?
            Ain't no faking that. Daniel Day-Lewis, I got a notepad right here
            bro.{" "}
          </p>
          <p>
            Out of all the movies listed here, I think this is the one I'm
            quickest to recommend these days cuz I'm pretty sure it's a perfect
            movie during times like these. At least for me, it's encouraging to
            see somebody be extremely good at...not dying.
          </p>
          <p>
            Ard this article is ballooning now so imma just keep the rest of
            these short:
          </p>
          <p className="highlight">
            <i>Next Friday</i> (2000) dir. Steve Carr
          </p>
          <p>
            LOL when they gave the dog (Chico?) the weed brownie he really was
            looking demolished yo fully truly smoked out 10/10 geekable I'm
            cracking up thinking bout it.
          </p>
          <p className="highlight">
            <i>Black Like Meow </i>(2020) dir. Zuff Shoya
          </p>
          <p>
            Here go my bro imma just let this performance{" "}
            <a
              href="https://vimeo.com/430291373"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              speak for itself
            </a>
            .
          </p>
          <p className="highlight">
            <i>Animal Farm</i> (1954) dir. Joy Batchelor, Jay Halas
          </p>
          <p>
            Ard I know I said no animation but since when do I color inside the
            lines? Hold up, look up at the URL real quick. Oh yeah, that's what
            I thought chew me.
          </p>
          <p>
            Anyway I really don't care if you think Stalin is good or bad, long
            as you know that his particular 'failures' at government don't
            justify denying people universal healthcare.
          </p>
          <p className="highlight">
            <i>Rise of the Planet of the Apes </i>(2011) dir. Rupert Wyatt
          </p>
          <p>
            Yeah the ape who played Caesar snapped. I'm not sure exactly where
            they found all them talking apes at for this franchise but they did
            they thing, each and every one of em. We might gotta get like how
            they got in the movie 👀.
          </p>
          <p className="highlight">
            <i>Life of Pi</i> (2012) dir. Ang Lee
          </p>
          <p>
            They say they used a CG tiger, but my cousin uncle friend uncle
            niece uncle uncle uncle worked in craft services on set and said
            they really used a real tiger and that's gnarly.
          </p>
          <p>We need a spiritual awakening in this world.</p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>
  )
}
export default Animals
